if (Glide) {
  const glide = new Glide('.glide', {
    type: 'carousel',
    gap: 0,
    perView: 2,
    breakpoints: {
      480: {
        perView: 1
      }
    }
  }).mount();
}
